import type { PassThrough } from '@primevue/core';
import type { TabsPassThroughOptions } from 'primevue/tabs';

export const tabsPassthrough: PassThrough<TabsPassThroughOptions> = {
  tabList: {
    root: {
      class: 'bg-base',
    },
    content: {
      class: 'flex-center',
    },
    tabList: {
      class: 'max-w-max bg-primary-layer p-1 rounded-xl',
    },
    activeBar: {
      class: 'hidden',
    },
  },
  tabPanel: {
    root: {
      class: 'p-2',
    },
  },
};

export const responsibleGamingTabsPassthrough: PassThrough<TabsPassThroughOptions> =
  {
    tabList: {
      root: {
        class: 'bg-base rounded-xl p-1 mb-1 mx-2',
      },
      content: {
        class: 'flex-center',
      },
      tabList: {
        class: 'w-full flex gap-2',
      },
      activeBar: {
        class: 'hidden',
      },
    },
    tab: {
      root: ({ context }) => ({
        class: [
          'rounded-xl w-full py-2 capitalize font-bold',
          { 'bg-layer-2': context.active },
        ],
      }),
    },
  };
